import React, { useState } from 'react';
import clsx from 'clsx';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  ButtonBase,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
// components
import {
  Building as AddressIcon,
  CallCalling as PhoneIcon,
  Copy as CopyIcon,
  Sms as EmailIcon,
} from 'components/CustomIcon';
// hooks
import { useLocales } from 'hooks';
//
import { stylesRoot } from './styles';

export type PhoneDataType = {
  country?: string;
  number: string;
};

interface AddressesBoxItemProps {
  item?: any;
}

interface AddressesBoxItemAllProps extends AddressesBoxItemProps {
  className?: string;
}

const PREFIX = 'AddressesBoxItem';
const classes = {
  root: `${PREFIX}-root`,
  itemIcon: `${PREFIX}-itemIcon`,
  showBox: `${PREFIX}-showBox`,
  showText: `${PREFIX}-showText`,
  copyButton: `${PREFIX}-copyButton`,
};

const StyledRoot = styled(List, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const AddressesBoxItem = (props: AddressesBoxItemAllProps) => {
  const { className: classNameProp, item } = props;
  const { translate: t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const [showPhoneNumber, setShowPhoneNumber] = useState<number>(-1);
  const [showEmail, setShowEmail] = useState<boolean>(false);

  return (
    <Box>
      <StyledRoot
        classes={{ root: clsx(classes.root, classNameProp) }}
        disablePadding
        subheader={
          item?.country && item?.showCountry ? (
            <Typography variant="h6" gutterBottom>
              {item.country}
            </Typography>
          ) : null
        }
      >
        {item?.address && (
          <ListItem disableGutters>
            <ListItemIcon classes={{ root: classes.itemIcon }}>
              <AddressIcon />
            </ListItemIcon>
            <ListItemText
              primary={<Typography dangerouslySetInnerHTML={{ __html: item.address }} />}
            />
          </ListItem>
        )}

        {item?.phones &&
          item?.phones.map((phone: PhoneDataType, index: number) => (
            <ListItem disableGutters key={index}>
              <ListItemIcon classes={{ root: classes.itemIcon }}>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box className={classes.showBox}>
                    <Typography component="div">
                      {showPhoneNumber === index && phone.number ? (
                        <a href={`tel:${phone.number}`}>{phone.number}</a>
                      ) : (
                        `${phone.number.substring(0, 5)}...`
                      )}
                      {showPhoneNumber !== index && (
                        <ButtonBase
                          component={Typography}
                          className={classes.showText}
                          disableRipple
                          disableTouchRipple
                          onClick={() => setShowPhoneNumber(index)}
                        >
                          {t('footer.showPhoneText')} ({phone.country})
                        </ButtonBase>
                      )}
                    </Typography>
                  </Box>
                }
              />
            </ListItem>
          ))}

        {item?.email && (
          <ListItem disableGutters>
            <ListItemIcon classes={{ root: classes.itemIcon }}>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Box className={classes.showBox}>
                  {showEmail ? (
                    <>
                      <Typography component={Link} href={`mailto:${item.email}`} target="_blank">
                        {item.email}
                      </Typography>
                      <CopyToClipboard
                        text={item.email}
                        onCopy={() =>
                          enqueueSnackbar(t('common.successfullyCopiedToClipboard'), {
                            variant: 'success',
                          })
                        }
                      >
                        <Tooltip title={t('common.copy') as string}>
                          <IconButton size="small" className={classes.copyButton} color="primary">
                            <CopyIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      </CopyToClipboard>
                    </>
                  ) : (
                    <ButtonBase
                      component={Typography}
                      className={classes.showText}
                      disableRipple
                      disableTouchRipple
                      onClick={() => setShowEmail(true)}
                    >
                      {t('footer.showEmailText')}
                    </ButtonBase>
                  )}
                </Box>
              }
            />
          </ListItem>
        )}
      </StyledRoot>
    </Box>
  );
};

export default AddressesBoxItem;
