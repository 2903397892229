import React from 'react';
import { graphql } from 'gatsby';
// material
import { Theme } from '@mui/material/styles';
import { useMediaQuery, Grid } from '@mui/material';
// layouts
import { MainLayout } from 'layouts';
// components
import { ContentBox } from 'components';
// page components
import { AddressesBox, ContactForm } from 'pages-components/contact';
// hooks
import { useLocales } from 'hooks';
// helpers
import getNativeFormsProperties, { NativeFormsIdsType } from 'helpers/getNativeFormsUrl';

const ContactPage = ({ data: { page }, location, pageContext }: any) => {
  const { currentLang, translate: t } = useLocales();

  const mdDown = useMediaQuery(({ breakpoints }: Theme) => breakpoints.down('md'));
  const lgDown = useMediaQuery(({ breakpoints }: Theme) => breakpoints.down('lg'));

  const { frontmatter: frontMatter } = page;

  const FORM_ID: NativeFormsIdsType = 'contact';
  const formProperties = getNativeFormsProperties(FORM_ID, currentLang.value);

  return (
    <MainLayout
      metadata={frontMatter?.metadata}
      contact={false}
      path={location.pathname}
      locale={pageContext.locale}
    >
      <ContentBox>
        <Grid container spacing={lgDown ? 6 : 15} alignItems="center">
          <Grid item xs={12} md={6.5} order={{ xs: 2, sm: 1 }}>
            <AddressesBox
              title={t('pages.contact.addresses.title')}
              subtitle={t('pages.contact.addresses.subtitle')}
              data={frontMatter.data}
              smallScreen={mdDown}
            />
          </Grid>

          <Grid item xs={12} md={5.5} order={{ xs: 2, sm: 1 }}>
            <ContactForm
              title={t('pages.contact.contactForm.title')}
              form={formProperties.url}
              formId={formProperties.formId}
            />
          </Grid>
        </Grid>
      </ContentBox>
    </MainLayout>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query ($id: String!) {
    page: mdx(id: { eq: $id }) {
      frontmatter {
        title
        slug
        metadata {
          title
          description
          og_title
          og_description
          og_type
          og_image {
            publicURL
            name
          }
          fb_app_id
          twitter_card
          twitter_creator
          twitter_title
          twitter_description
          twitter_image {
            publicURL
            name
          }
          twitter_url
          keywords
        }
        data {
          country
          showCountry
          address
          phones {
            number
            country
          }
          email
        }
      }
    }
  }
`;
