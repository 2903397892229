import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints, palette, spacing } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: palette.background.default,
        [breakpoints.up('sm')]: {
          padding: spacing(1),
        },
        [breakpoints.up('lg')]: {
          padding: spacing(2),
        },
        [`& .${classes.content}`]: {
          position: 'relative',
          zIndex: 1,
          '& > *:not(:last-child)': {
            paddingBottom: spacing(4),
            [breakpoints.up('lg')]: {
              paddingBottom: spacing(6),
            },
          },
        },
        [`& .${classes.image}`]: {
          display: 'flex',
          flexDirection: 'column-reverse',
          position: 'absolute',
          pointerEvents: 'none',
          width: 200,
          bottom: 0,
          right: -1,
          transform: 'translateY(50%)',
          '& .gatsby-image-wrapper': {
            display: 'block !important',
          },
        },
      },
    },
  };
};
