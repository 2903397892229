import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints, spacing } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        position: 'relative',
        [breakpoints.up('sm')]: {
          padding: spacing(1, 0),
        },
        [breakpoints.up('lg')]: {
          padding: spacing(2, 0),
        },
        [`& .${classes.nativeForms}`]: {
          '& .nf-question': {
            '&:last-child': {
              marginTop: spacing(2),
              [breakpoints.down('sm')]: {
                '& .nf-recaptcha-wrapper': {
                  margin: '0 auto',
                },
              },
              [breakpoints.between('sm', 'md')]: {
                alignItems: 'center',
                flexDirection: 'row',
                '& > *': {
                  margin: 0,
                  padding: '0 !important',
                },
                '& .nf-submit-button-container': {
                  flex: 1,
                },
              },
              [breakpoints.up('md')]: {
                '& .nf-recaptcha-wrapper': {
                  margin: '0 auto',
                },
              },
            },
          },
          '& .nf-submit-button-container': {
            '& .nf-submit-button': {
              width: 'auto',
              minWidth: 240,
              margin: '0 auto',
              [breakpoints.up('md')]: {
                minWidth: 320,
              },
            },
          },
        },
      },
    },
  };
};
