import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { palette, spacing, typography } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        [`& .${classes.itemIcon}`]: {
          minWidth: spacing(6),
        },
        [`& .${classes.showBox}`]: {
          display: 'flex',
          alignItems: 'center',
          fontWeight: typography.fontWeightMedium,
          '& span, & p, & a, & div': {
            fontWeight: 'inherit',
            display: 'inline',
          },
          '& >*:not(:last-child)': {
            marginRight: spacing(0.5),
          },
        },
        [`& .${classes.showText}`]: {
          verticalAlign: 'baseline',
          color: palette.primary.main,
          padding: spacing(0, 0.5),
          borderRadius: spacing(1),
        },
        [`& .${classes.copyButton}`]: {
          marginTop: -4,
          marginBottom: -4,
          padding: 6,
        },
      },
    },
  };
};
