import React, { useState } from 'react';
import clsx from 'clsx';
// material
import { styled } from '@mui/material/styles';
// components
import { BoxWithHeader, NativeFormsComponent } from 'components';
// types
import { NativeFormsComponentProps } from 'components/NativeFormsComponent/NativeFormsComponent';
//
import { stylesRoot } from './styles';

interface ContactFormProps extends NativeFormsComponentProps {
  classesChildren?: any;
  title?: string;
}

interface ContactFormAllProps extends ContactFormProps {
  className?: string;
}

const PREFIX = 'ContactForm';
const classes = {
  root: `${PREFIX}-root`,
  nativeForms: `${PREFIX}-nativeForms`,
};

const StyledRoot = styled(BoxWithHeader, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const ContactForm = (props: ContactFormAllProps) => {
  const {
    className: classNameProp,
    title,
    form,
    formId,
    formIdInputSelector,
    classesChildren,
  } = props;

  const [showHeader, setShowHeader] = useState<boolean>(false);

  return (
    <StyledRoot
      header={showHeader && title}
      textAlign="left"
      headerProps={{ variant: 'h4', component: 'h1' }}
      spaceHeader="small"
      classesChildren={{ root: clsx(classes.root, classNameProp, classesChildren?.root) }}
    >
      <NativeFormsComponent
        className={classes.nativeForms}
        form={form}
        formId={formId}
        formIdInputSelector={formIdInputSelector}
        onLoad={() => setShowHeader(true)}
        onSend={() => setShowHeader(false)}
      />
    </StyledRoot>
  );
};

ContactForm.defaultProps = {
  formIdInputSelector: '#formId',
} as Partial<ContactFormAllProps>;

export default ContactForm;
