import React from 'react';
import clsx from 'clsx';
// gatsby
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
// material
import { styled } from '@mui/material/styles';
import { Card, CardContent } from '@mui/material';
// components
import { BoxWithHeader } from 'components';
// page components
import { AddressesBoxItem } from 'pages-components/contact';
//
import { stylesRoot } from './styles';

export type PhoneDataType = {
  country?: string;
  number: string;
};

export type AddressesBoxDataType = {
  country?: string;
  showCountry?: boolean;
  address?: string;
  phones?: PhoneDataType[];
  email?: string;
};

interface AddressesBoxProps {
  classesChildren?: any;
  title?: string;
  subtitle?: string;
  data?: AddressesBoxDataType[];
  smallScreen?: boolean;
}

interface AddressesBoxAllProps extends AddressesBoxProps {
  className?: string;
}

const PREFIX = 'AddressesBox';
const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  image: `${PREFIX}-image`,
};

const StyledRoot = styled(Card, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const AddressesBox = (props: AddressesBoxAllProps) => {
  const { className: classNameProp, title, subtitle, data, smallScreen, classesChildren } = props;

  const dataQuery = useStaticQuery(graphql`
    query {
      rightTopLineImage: file(relativePath: { eq: "product/line-tr2.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 700)
        }
      }
    }
  `);

  return (
    <StyledRoot
      variant="elevation"
      elevation={0}
      classes={{ root: clsx(classes.root, classNameProp, classesChildren?.root) }}
    >
      <CardContent className={classes.content}>
        <BoxWithHeader
          header={title}
          subheader={subtitle}
          textAlign="left"
          headerProps={{ variant: 'h5', component: 'h2' }}
          subheaderProps={{ variant: 'subtitle2', color: 'primary', component: 'h3' }}
        />

        {data &&
          data.map((item: AddressesBoxDataType, index) => (
            <AddressesBoxItem key={index} item={item} />
          ))}
      </CardContent>

      {!smallScreen && (
        <div className={classes.image}>
          <GatsbyImage
            image={dataQuery.rightTopLineImage.childImageSharp.gatsbyImageData}
            alt=""
            title=""
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
      )}
    </StyledRoot>
  );
};

export default AddressesBox;
